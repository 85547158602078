import { render, staticRenderFns } from "./ClassHowToBilling.vue?vue&type=template&id=bcbe3e88&scoped=true"
import script from "./ClassHowToBilling.vue?vue&type=script&lang=js"
export * from "./ClassHowToBilling.vue?vue&type=script&lang=js"
import style0 from "./ClassHowToBilling.vue?vue&type=style&index=0&id=bcbe3e88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcbe3e88",
  null
  
)

export default component.exports