<template>
  <el-dialog
    width="560px"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <h5>課程如何計費？</h5>
    <div class="content">
      <div
        :class="`pointer options ${billingType === 'project' ? 'selectedOptions' : ''}`"
        style="margin-right: 8px"
        @click="billingType = 'project'"
      >
        <span class="icon">
          <i class="fas fa-file-invoice-dollar" />
        </span>
        <h2>專案課程</h2>
        <p>（如團體班、固定費用家教課）</p>
      </div>
      <div
        :class="`pointer options ${billingType === 'clock' ? 'selectedOptions' : ''}`"
        style="margin-left: 8px"
        @click="billingType = 'clock'"
    >
        <span class="icon">
          <i class="far fa-clock" />
        </span>
        <h2>鐘點課程</h2>
        <p>（如家教班、彈性時數團體班）</p>
      </div>
    </div>
    <div :class="`isCounseling ${billingType === 'project' ? 'openIsCounseling' : ''}`">
      <h5>是什麼樣的課呢？</h5>
      <div class="content">
        <div
          :class="`pointer options ${classIsCounseling === 0 ? 'selectedOptions' : ''}`"
          style="margin-right: 8px"
          @click="classIsCounseling = 0"
        >
          <span class="icon">
            <i class="fas fa-copyright" />
          </span>
          <h2>課程</h2>
        </div>
        <div
          :class="`pointer options ${classIsCounseling === 1 ? 'selectedOptions' : ''}`"
          style="margin-left: 8px"
          @click="classIsCounseling = 1"
        >
          <span class="icon">
            <i class="fas fa-address-book" />
          </span>
          <h2>升學輔導</h2>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="goToPreviousPage">
        回上一頁
      </el-button>
      <el-button type="primary" @click="goToCreateClassPage">
        {{ $t("message.continue") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      billingType: null,
      classIsCounseling: 1
    };
  },
  computed: {},
  async mounted() {},
  async created() {},
  methods: {
    goToPreviousPage() {
      this.$router.go(-1);
    },
    goToCreateClassPage() {
      if (this.billingType) {
        if (this.billingType === "project") {
          if (this.classIsCounseling) {
            this.$router.push({
              name: "privateCreate",
              params: {
                privateType: "counseling"
              }
            });
          } else {
            this.$router.push({
              name: "sessionClassesCreate"
            });
          }
        } else if (this.billingType === "clock") {
          this.$router.push({
            name: "privateCreate",
            params: {
              privateType: "private-lessons"
            }
          });
        }
      } else {
        this.$message.warning("請選擇要新增課程的計費方式！");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    margin: 16px 0px;
}

.options {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 4px solid #bababa;
    border-radius: 8px;
    width: 1000px;
    transition: border 0.2s ease;

    .icon {
        color: #bababa;
        font-size: 84px;
        transition: color 0.2s ease;
    }

    h2 {
        color: #bababa;
    }

    p {
        color: #bababa;
        font-size: 16px;
        transition: color 0.2s ease;
    }
}

.selectedOptions {
    border: 4px solid #68b488;
    transition: border 0.2s ease;
    width: 1000px;

    .icon {
        color: #68b488;
        transition: color 0.2s ease;
    }

    h2 {
        color: #68b488;
    }

    p {
        font-weight: bolder;
        color: #68b488;
        font-size: 16px;
        transition: color 0.2s ease;
    }
}

.isCounseling {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;
}

.openIsCounseling {
  max-height: 300px;
  transition: max-height 0.5s ease-in-out;
}
</style>
