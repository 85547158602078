<template>
  <div class="container-fluid">
    <ClassHowToBilling :visible="true" />
  </div>
</template>

<script>
import ClassHowToBilling from "@/components/courseSessions/ClassHowToBilling";

export default {
  components: {
    ClassHowToBilling
  },
  data() {
    return {};
  },
  mixins: [],
  computed: {
  },
  created() {
  },
  methods: {
  }
};
</script>